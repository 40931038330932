<template>
  <div class="wrap">
    <div class="main" v-if="formShow">
      <van-cell-group inset>
        <van-field :label-width="120" @update:model-value="inputPhone" maxlength="11" v-model="phone" size="large"
          label="邀请人手机" placeholder="请输入手机号" />
        <van-cell>
          <template #title>
            <div class="cell-tit">身份校验</div>
          </template>
          <template #value>
            <div class="cell-val">{{ toInfo ? (toInfo.xm || '暂未设置') : '显示姓名' }}</div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="footer" v-if="formShow">
      <div class="btn" @click="submit">确定绑定</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { showDialog, showLoadingToast, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      phone: '',
      formShow: false,
      toInfo: null,
    }
  },
  created() {
    console.log(this.user_detail);
    if (!this.user_detail?.sjhm) {
      this.get_user_detail({ sjhm: this.$route.query.sjhm });
    }
  },
  watch: {
    user_detail: {
      handler(newVal) {
        console.log(newVal);
        if (newVal) {
          if (!newVal.sbid) {
            showDialog({ title: '提示', message: '请绑定手环后再操作' });
            this.formShow = false;
            return;
          }
          if (newVal.yqr) {
            showDialog({ title: '提示', message: '您已绑定邀请人' });
            this.formShow = false;
            return;
          }
          this.formShow = true;
        }
      }
    }
  },
  computed: {
    ...mapState(['user_detail']),
  },
  methods: {
    ...mapActions(['get_user_detail']),
    async inputPhone(phone) {
      if (phone.length == 11) {
        let { data: res } = await axios.get('/api/api/get_user_detail', {
          params: {
            sjhm: phone
          }
        });
        if (res.code) {
          this.toInfo = res.data;
        }
      }
    },
    async submit() {
      let hide = showLoadingToast({ message: '操作中...' });
      let { data: res } = await axios.get("/api/api/bind_yqr", {
        params: {
          sjhm: this.$route.query.sjhm,
          yqr: this.phone
        }
      });
      hide.close();
      if (res?.success) {
        showNotify({ type: 'success', message: res.msg || '编辑成功' });
      }
      else {
        showNotify({ type: 'danger', message: res.msg || '失败' })
      }
    }
  },

}
</script>

<style lang="less" scoped>
.wrap {
  .main {
    margin-top: 24px;

    .phone {}

    .cell-tit {
      border-radius: 5px;
      background: #3fb6c6;
      height: 24px;
      line-height: 24px;
      padding: 0 12px;
      font-size: 14px;
      color: #fff;
      display: inline-block;
    }

    .cell-val {}
  }

  .footer {
    margin-top: 24px;
    padding: 0 16px;

    .btn {
      border-radius: 5px;
      background: #3fb6c6;
      color: #fff;
      height: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>